<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout id="components-layout-demo-custom-trigger">
        <Sider />
        <a-layout class="section">
          <a-layout-content
            :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }"
          >
            <div class="container">
              <div ref="box">
                <div ref="header">
                  <a-page-header
                    style="border-bottom: 1px solid rgb(235, 237, 240)"
                    title="机器人管理"
                  >
                    <template slot="extra">
                      <a-button
                        class="editable-add-btn"
                        @click="handleAdd"
                        size="small"
                      >
                        新增
                      </a-button>
                    </template>
                  </a-page-header>
                </div>
                <a-table
                  :columns="columns"
                  :data-source="robotList"
                  :pagination="false"
                  :scroll="{ x: 500, y: table_h }"
                  :row-key="(record) => record.id"
                  size="middle"
                  ref="tableList"
                >
                  <span slot="avatar" slot-scope="avatar">
                    <img :src="avatar" alt="" />
                  </span>
                  <span slot="platform_name" slot-scope="platform_name">
                    <span v-for="(item, index) in platform_name" :key="item">
                      <span v-if="index != 0">,</span>
                      <span>{{ item }}</span>
                    </span>
                  </span>
                  <span slot="anchor_type_name" slot-scope="anchor_type_name">
                    <span v-for="(item, index) in anchor_type_name" :key="item">
                      <span v-if="index != 0">,</span>
                      <span>{{ item }}</span>
                    </span>
                  </span>
                  <span slot="btns" slot-scope="btns, num">
                    <a-button
                      class="editable-add-btn"
                      v-for="btn in btns"
                      :key="btn"
                      @click="handel(btn, num)"
                      size="small"
                      style="margin: 5px"
                    >
                      {{ btn }}
                    </a-button>
                  </span>
                </a-table>
                <div ref="pagination">
                  <a-pagination
                    v-if="pageshow"
                    :default-current="currentPage"
                    :defaultPageSize="defaultPageSize"
                    :page-size-options="pageSizeOptions"
                    show-size-changer
                    show-quick-jumper
                    :total="total_num"
                    :show-total="(total) => `共 ${total} 条`"
                    @showSizeChange="onShowSizeChange"
                    @change="onPageChange"
                  >
                    <template slot="buildOptionText" slot-scope="props">
                      <span>{{ props.value }}条/页</span>
                    </template>
                  </a-pagination>
                </div>
                <!-- 新增编辑机器人 -->
                <a-modal
                  v-model="visible"
                  :title="modelTitle"
                  :confirm-loading="confirmLoading"
                  @ok="handleOk"
                  ok-text="确认"
                  cancel-text="取消"
                >
                  <a-form :form="addform" @submit="handleSubmit">
                    <a-form-item label="昵称：">
                      <a-input
                        v-decorator="[
                          'nickname',
                          {
                            rules: [{ required: true, message: '请输入昵称!' }],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="手机号：">
                      <a-input
                        v-decorator="[
                          'mobile',
                          {
                            rules: [
                              { required: true, message: '请输入手机号!' },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="邀请人数：">
                      <a-input-number
                        v-decorator="[
                          'inv_nu',
                          {
                            rules: [
                              { required: true, message: '请输入邀请人数!' },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="头像：" extra="最多支持1张">
                      <a-input
                        v-decorator="[
                          'avatar',
                          {
                            rules: [{ required: true, message: '请选择头像!' }],
                          },
                        ]"
                        type="hidden"
                      ></a-input>
                      <a-upload
                        name="file"
                        list-type="picture"
                        class="upload-list-inline"
                        :fileList="fileList"
                        :customRequest="changeImg"
                        @change="getchangeImg"
                        :remove="removeImg"
                        :beforeUpload="handleBeforeUpload"
                      >
                        <a-button :disabled="!uploadBtn">
                          <a-icon type="upload" /> 上传图片
                        </a-button>
                      </a-upload>
                    </a-form-item>
                    <a-form-item label="平台类型：">
                      <a-checkbox-group
                        v-decorator="[
                          'platform',
                          {
                            rules: [
                              { required: true, message: '请选择平台类型!' },
                            ],
                          },
                        ]"
                      >
                        <a-checkbox
                          v-for="item in platformList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.platform_name }}
                        </a-checkbox>
                      </a-checkbox-group> </a-form-item
                    ><a-form-item label="主播类型：">
                      <a-checkbox-group
                        v-decorator="[
                          'anchor_type',
                          {
                            rules: [
                              { required: true, message: '请选择主播类型!' },
                            ],
                          },
                        ]"
                      >
                        <a-checkbox
                          v-for="item in anchorTypeList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.type }}
                        </a-checkbox>
                      </a-checkbox-group>
                    </a-form-item>
                  </a-form>
                </a-modal>
                <!-- 营销数据 -->
                <a-modal
                  width="90%"
                  v-model="datavisible"
                  :title="modelTitle"
                  ok-text="新增"
                  @ok="billAdd"
                  cancel-text="取消"
                  class="bill"
                >
                  <div>
                    <a-table
                      :columns="innercolumns"
                      :data-source="userRobotBill"
                      :pagination="false"
                      :scroll="{ x: 900, y: 500 }"
                      :row-key="(record) => record.id"
                      size="middle"
                      ref="tableList"
                    >
                      <span slot="btns" slot-scope="btns, num"
                        ><a-button
                          class="editable-add-btn"
                          v-for="btn in btns"
                          :key="btn"
                          @click="billEdit(btn, num)"
                          size="small"
                          style="margin: 5px"
                        >
                          {{ btn }}
                        </a-button>
                      </span>
                    </a-table>
                    <div ref="pagination">
                      <a-pagination
                        v-if="pageshowBill"
                        :default-current="currentBillPage"
                        :defaultPageSize="pageSize"
                        show-quick-jumper
                        :total="total_billnum"
                        :show-total="(total) => `共 ${total} 条`"
                        @change="onBillPageChange"
                      >
                        <template slot="buildOptionText" slot-scope="props">
                          <span>{{ props.value }}条/页</span>
                        </template>
                      </a-pagination>
                    </div>
                  </div>
                </a-modal>
                <!-- 新增编辑营销数据 -->

                <a-modal
                  v-model="changeBillvisible"
                  :title="modelBillTitle"
                  :confirm-loading="confirmLoading"
                  @ok="handleBillOk"
                  ok-text="确认"
                  cancel-text="取消"
                >
                  <a-form :form="addbillform" @submit="handleSubmit">
                    <a-form-item label="榜单金额（元）：">
                      <a-input-number
                        :min="0"
                        :precision="2"
                        step="1"
                        v-decorator="[
                          'top_money',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入榜单金额（元）!',
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="每次增长的金额（分）：">
                      <a-input-number
                        :min="0"
                        :precision="0"
                        step="1"
                        v-decorator="[
                          'top_base_num',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入每次增长的金额（分）!',
                              },
                            ],
                          },
                        ]"
                      /> </a-form-item
                    ><a-form-item label="营销时间：">
                      <a-date-picker
                        v-decorator="[
                          'date',
                          {
                            initialValue: initialdate,
                            rules: [
                              { required: true, message: '请选择营销时间!' },
                            ],
                          },
                        ]"
                        show-time
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="请选择营销时间"
                        @change="onChange"
                      />
                    </a-form-item>
                  </a-form>
                </a-modal>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import moment from "moment";
import Header from "@/components/Header.vue";
import Sider from "@/components/Sider.vue";
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      addbillform: this.$form.createForm(this, { name: "bill_add" }),
      columns: [
        {
          title: "ID",
          key: "id",
          dataIndex: "id",
          align: "center",
        },
        {
          title: "昵称",
          key: "nickname",
          dataIndex: "nickname",
          align: "center",
        },
        {
          title: "头像",
          key: "avatar",
          dataIndex: "avatar",
          scopedSlots: { customRender: "avatar" },
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          dataIndex: "mobile",
          align: "center",
        },
        {
          title: "平台",
          key: "platform_name",
          dataIndex: "platform_name",
          align: "center",
          scopedSlots: { customRender: "platform_name" },
        },
        {
          title: "主播类型",
          key: "anchor_type_name",
          dataIndex: "anchor_type_name",
          scopedSlots: { customRender: "anchor_type_name" },
          align: "center",
        },
        {
          title: "邀请数",
          key: "inv_nu",
          dataIndex: "inv_nu",
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      innercolumns: [
        {
          title: "数据ID",
          key: "id",
          dataIndex: "id",
          align: "center",
        },
        {
          title: "机器人ID",
          key: "users_robot_id",
          dataIndex: "users_robot_id",
          align: "center",
        },
        {
          title: "榜单金额（元）",
          key: "top_money",
          dataIndex: "top_money",
          align: "center",
        },
        {
          title: "每次增长最大金额（分）",
          key: "top_base_num",
          dataIndex: "top_base_num",
          align: "center",
        },
        {
          title: "营销时间",
          key: "date",
          dataIndex: "date",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      fileList: [],
      confirmLoading: false,
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      robotList: [],
      canUpload: "",
      platformList: [],
      anchorTypeList: [],
      datavisible: false,
      changeBillvisible: false,
      userRobotBill: [],
      pageSize: 10,
      currentBillPage: 1,
      pageshowBill: false,
      total_billnum: 0,
      initialdate: null,
      modelBillTitle: "",
    };
  },
  components: {
    Header,
    Sider,
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight - this.$refs.pagination.offsetHeight -this.$refs.header.offsetHeight - 45;
      // this.$refs.pagination.offsetHeight -
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //平台类型
    getPlatform() {
      var that = this;
      that.axios.get("/app/platform/auth/platformType").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          that.platformList = res.data.data;
        }
      });
    },
    //主播类型
    getanchorType() {
      var that = this;
      that.axios.get("/app/platform/auth/anchorType").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          that.anchorTypeList = res.data.data;
        }
      });
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    onBillPageChange(pageNumber) {
      var that = this;
      this.currentBillPage = pageNumber;
      that.getBillList();
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/userRobot/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var robotList = res.data.data;
            for (var i = 0; i < robotList.length; i++) {
              robotList[i].btns = ["编辑", "营销数据"];
            }
            that.changeTableH();
            that.robotList = robotList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //获取营销列表
    getBillList() {
      var that = this;
      var data = {};
      data.limit = that.pageSize;
      data.page = that.currentBillPage;
      data.users_robot_id = that.id;
      that.axios
        .get("/admin/userRobotBill/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var userRobotBill = res.data.data;
            for (var i = 0; i < userRobotBill.length; i++) {
              userRobotBill[i].btns = ["编辑"];
            }
            // that.changeTableH();
            that.userRobotBill = userRobotBill;
            that.total_billnum = res.data.count;
            that.currentBillPage = res.data.currentPage;
          }
        });
      that.$nextTick(() => {
        that.pageshowBill = true;
      });
    },
    //上传图片
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.post("/admin/upload/image", formData).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var img = res.data.data.path_url;
          that.addform.setFieldsValue({
            avatar: img,
          });
          that.fileList = [
            {
              uid: info.file.uid,
              name: info.file.name,
              status: "done",
              url: "" + img,
              thumbUrl: "" + img,
            },
          ];
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file) {
      if (file.type.indexOf("image") < 0) {
        this.$message.error("仅支持上传图片");
        this.canUpload = false;
        return false;
      } else {
        this.canUpload = true;
        return true;
      }
    },
    getchangeImg(info) {
      if (!this.canUpload) {
        info.fileList = [];
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.addform.setFieldsValue({
            template_url: "",
          });
        }
      }
    },
    //新增机器人
    handleAdd() {
      this.getPlatform();
      this.getanchorType();
      this.visible = true;
      this.modelTitle = "新增机器人";
      this.id = "";
      this.uploadBtn = true;
      this.fileList = [];
      var that = this;
      that.$nextTick(() => {
        that.addform.setFieldsValue({
          nickname: "",
          mobile: "",
          avatar: "",
          platform: [],
          anchor_type: [],
          inv_nu: "",
        });
      });
    },
    //新增、编辑提交表单
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          if (!/^1\d{10}$/.test(values.mobile)) {
            that.$message.error("请输入正确的手机号码");
            return false;
          }
          if (!that.id) {
            that.axios.post("/admin/userRobot/save", data).then((res) => {
              that.confirmLoading = false;
              //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
              if (that.$code(res)) {
                that.getSearchList({});
              }
              that.visible = false;
            });
          } else {
            that.axios
              .post("/admin/userRobot/modify/" + that.id, data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },

    // 操作（编辑，营销数据）
    handel(btn, num) {
      var that = this;
      this.getPlatform();
      this.getanchorType();
      that.id = num.id;
      if (btn == "编辑") {
        that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
        that.axios.get("/admin/userRobot/one/" + num.id).then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            that.uploadBtn = false;
            that.fileList = [
              {
                uid: "-1",
                name: "",
                status: "done",
                url: res.data.data.avatar,
                thumbUrl: res.data.data.avatar,
              },
            ];
            that.visible = true;
            that.modelTitle = "编辑机器人";
            that.$nextTick(() => {
              that.addform.setFieldsValue({
                nickname: res.data.data.nickname,
                mobile: res.data.data.mobile,
                avatar: res.data.data.avatar,
                platform: res.data.data.platform,
                anchor_type: res.data.data.anchor_type,
                inv_nu: res.data.data.inv_nu,
              });
            });
          }
        });
      }
      if (btn == "营销数据") {
        this.modelTitle = "营销数据";
        that.datavisible = true;
        that.pageshowBill = false;
        that.getBillList();
      }
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
    onChange(value, dateString) {
      this.date = dateString;
    },
    //新增营销数据
    billAdd() {
      this.changeBillvisible = true;
      this.modelBillTitle = "新增营销数据";
      this.dataid = "";
      this.uploadBtn = true;
      var that = this;
      that.date = "";
      that.$nextTick(() => {
        that.addbillform.setFieldsValue({
          date: "",
          top_base_num: "",
          top_money: "",
        });
      });
    },
    //编辑营销数据
    billEdit(btn, num) {
      var that = this;
      this.dataid = num.id;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.get("/admin/userRobotBill/one/" + num.id).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          that.uploadBtn = false;
          that.changeBillvisible = true;
          that.modelBillTitle = "编辑营销数据";
          that.date = res.data.data.date;
          that.$nextTick(() => {
            that.addbillform.setFieldsValue({
              top_money: res.data.data.top_money,
              top_base_num: res.data.data.top_base_num,
              date: res.data.data.date
                ? moment(res.data.data.date, "YYYY-MM-DD hh-mm-ss")
                : res.data.data.date,
            });
          });
        }
      });
    },
    //新增、编辑营销数据提交表单
    handleBillOk() {
      var that = this;
      that.confirmLoading = true;
      that.addbillform.validateFields((error, values) => {
        if (!error) {
          if (values.indate) {
            that.$delete(values, "indate");
          }
          values.date = that.date;
          values.users_robot_id = that.id;
          var data = values;
          if (!that.dataid) {
            that.axios.post("/admin/userRobotBill/save", data).then((res) => {
              that.confirmLoading = false;
              //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
              if (that.$code(res)) {
                that.getBillList();
                that.changeBillvisible = false;
              }
            });
          } else {
            that.axios
              .post("/admin/userRobotBill/modify/" + that.dataid, data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getBillList();
                  that.changeBillvisible = false;
                }
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
      // this.visible = false;
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body form {
  overflow: hidden;
}
.ant-input-number {
  width: 100%;
}
.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}
/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}
/deep/.m-colorPicker .box {
  right: 0;
}
/deep/.ant-form-item label {
  margin-left: 8px;
}
</style>